:root{
    /*Light Theme*/
    --light:         #FFFFFF;
    --white:         #F5F5F5;
    --lightgrey:     #F0EFEF;
    --grey:          #E3E3E3;
    --darkgrey:      #CCCCCB;

    /*Dark Theme*/
    --vanta:         #000000;
    --dark:          #1E2A33;
    --lightblack:    #2F3D47;
    --black:         #25323D;
    --darkblack:     #18242D;

    /*text*/
    --blacktxt:      #282828;
    --greytxt:       #707070;
    --whitetxt:      #F0EFEF;
    --darktxt:       #CCCCCE;

    --focus:         #C83AB0;
    --lightfocus:    #D959B7;

    --darkmain:      #347175;
    --main:          #5BB6B0;
    --lightmain:     #75BEB8;

    --green:         #34C650;
    --orange:        #FAAE32;

    --red:           #FF3F50;
    --redlight:      #ff6f7b;

    --darkfb:        #3B5998;
    --fb:            #4267B2;
    --lightfb:       #90A7CE;

    --darkggl:       #CC4125;
    --ggl:           #DB4437;
    --lightggl:      #DD7E6B;

    --bigpads: 1.5rem 2.5rem;
    --bigtxt: 1.8rem;
    --bigsvg: 2.5rem;

    --mediumpads: 1rem 2rem;
    --mediumtxt: 1.6rem;
    --mediumsvg: 2rem;

    --smallpads: .5rem 1rem;
    --smalltxt: 1.4rem;
    --smallsvg: 1.6rem;

    --smallbtn: .5rem 0rem .5rem 0rem;
    --mediumbtn: 1rem 1rem 1rem 1rem;
    --bigbtn: 1.5rem 1.5rem 1.5rem 1.5rem;
}

